import { axiosIns } from "../../helpers/axios";
import i18n from "../../i18n";

export const state = {
  data: [],
  providerErrors: [],
  fields: [
    {
      key: "result",
      sortable: true,
      label: i18n.t("betDetail.result").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "title",
      sortable: true,
      label: i18n.t("technicalSupport.title").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "info",
      sortable: true,
      label: i18n.t("common.description").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "updatedAt",
      sortable: true,
      label: i18n.t("currencies.lastUpdate").toUpperCase(),
      class: "space-nowrap",
    },
    {
      key: "actions",
      sortable: true,
      label: i18n.t("actions.text").toUpperCase(),
      class: "space-nowrap",
    },
  ],
  success: false,
  alert_message: "",
};

export const getters = {
  getData() {
    return state.data;
  },
  getErrorCodes(state) {
    return state.providerErrors;
  },
  getErrorCodesFormatted(state) {
    const newCodes = state.providerErrors
      .map((el) => el.result)
      .sort((a, b) => a - b);
    if (!newCodes.length) return newCodes;
    newCodes.unshift("ALL");
    return newCodes;
  },
  getFields() {
    return state.fields;
  },
  getSuccess() {
    return state.success;
  },
  getAlertMessage() {
    return state.alert_message;
  },
};

export const mutations = {
  setData(state, payload) {
    state.data = payload;
  },
  setErrorCodes(state, payload) {
    state.providerErrors = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setAlertMessage(state, payload) {
    state.alert_message = payload;
  },
};

export const actions = {
  async fetchErrorCodes({ commit }) {
    try {
      const { data, status } = await axiosIns.get("provider-error");

      if (status >= 400) throw new Error("ERROR GETTING PROVDER ERRORS");

      const { providerErrors } = data;

      commit("setErrorCodes", providerErrors || []);
    } catch (error) {
      console.log("ERROR GET ERROR CODES", error);
      commit("setErrorCodes", []);
    }
  },
  async fetchErrorCodesPaginated({ commit }, filter) {
    try {
      const { data, status } = await axiosIns.get("provider-error/pagination", {
        params: { ...filter },
      });

      if (status >= 400) throw new Error("ERROR GETTING PROVDER ERRORS");

      commit(
        "setData",
        data.data.map((el) => ({
          ...el,
          createdAt: new Date(el.createdAt).toLocaleString(),
          updatedAt: new Date(el.updatedAt).toLocaleString(),
        }))
      );
    } catch (error) {
      console.log("ERROR GET ERROR CODES", error);
      commit("setData", []);
    }
  },
  async create({ commit }, data) {
    try {
      const { status } = await axiosIns.post("provider-error", data);

      if (status > 201) throw new Error("ERROR CREATING");

      commit("setSuccess", true);
      commit("setAlertMessage", "INCIDENCIA CREADA");
    } catch (error) {
      console.log("ERROR CREATING PROVIDER ERROR", error);
      commit("setSuccess", false);
      commit("setAlertMessage", "ERROR CREANDO INCIDENCIA");
    }
  },
  async update({ commit }, data) {
    const { _id, ...rest } = data;

    try {
      const { status } = await axiosIns.patch(`provider-error/${_id}`, rest);

      if (status > 201) throw new Error("ERROR UPDATING");

      commit("setSuccess", true);
      commit("setAlertMessage", "INCIDENCIA ACTUALIZADA");
    } catch (error) {
      console.log("ERROR UPDATING PROVIDER ERROR", error);
      commit("setSuccess", false);
      commit("setAlertMessage", "ERROR ACTUALIZANDO INCIDENCIA");
    }
  },
  async remove({ commit }, _id) {
    try {
      const { status } = await axiosIns.delete(`provider-error/${_id}`);

      if (status > 201) throw new Error("ERROR REMOVE");

      commit("setSuccess", true);
      commit("setAlertMessage", "INCIDENCIA ELIMINADA");
    } catch (error) {
      console.log("ERROR REMOVING PROVIDER ERROR", error);
      commit("setSuccess", false);
      commit("setAlertMessage", "ERROR ELIMINANDO INCIDENCIA");
    }
  },
};

import {
  axiosIns,
  axiosInsWheel,
  axiosInsDragonTiger,
} from "../../helpers/axios/index";
import { formatData } from "../helpers/formaDataApi";
import { requestSuccess } from "../helpers/requestSuccess";
import store from "../store";
import { sprint_gaming_apis_active } from "../../helpers/axios/wheelApi";
import { CLIENT_ROLE, OPERATOR_ROLE } from "../../helpers/CONSTANTS";
import { GamesTypes } from "../helpers/gamesTypes";
import { currenciesFields } from "../fields";

export const state = {
  currencies: [],
  fluctuation: [],
  success: false,
};

export const getters = {
  getCurrencies() {
    return state.currencies;
  },
  getSuccess() {
    return state.success;
  },
  getFluctuation() {
    return state.fluctuation;
  },
  getCurrenciesFields() {
    return currenciesFields;
  },
};

export const mutations = {
  setCurrencies(state, payload) {
    state.currencies = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  setFluctuation(state, payload) {
    state.fluctuation = payload;
  },
};

export const actions = {
  async fetchCurrencies({ commit }, { role, user }) {
    try {
      switch (role) {
        case CLIENT_ROLE:
          {
            const currencies = await store.dispatch(
              "clients/fetchClientCurrencies",
              {
                path: "clients",
                id: user.client,
              }
            );
            commit("setCurrencies", currencies);
          }
          break;
        case OPERATOR_ROLE:
          {
            await store.dispatch("operators/fetchOperatorCurrencies", {
              path: "operators",
              id: user.operator,
            });
            commit("setCurrencies", this.getOperatorCurrencies);
          }
          break;

        default:
          {
            const { data, status } = await axiosIns.get("/currencies");
            if (status > 200) return commit("setCurrencies", []);
            if (!data.ok) return commit("setCurrencies", []);

            const adminCurrencies = sprint_gaming_apis_active
              ? await store.dispatch("currencies/fetchCurrenciesAdminApi")
              : [];

            const formatted = formatData(data.currencies, adminCurrencies);

            commit("setCurrencies", formatted);
          }
          break;
      }
    } catch (error) {
      console.log("error -> fetchCurrencies", error);
    }
  },
  async fetchCurrenciesAdminApi() {
    try {
      const { data, status } = await axiosInsWheel.get("/currency");

      if (status > 200) return [];
      if (data.error) return [];

      const availablesCurrencies = data.currencies.map((curr) => {
        const { isoCode: short, usdRateChange: usdExchange } = curr;
        return { ...curr, short, symbol: short, usdExchange };
      });
      return availablesCurrencies;
    } catch (error) {
      console.log("error -> fetchCurrencies", error);
      return [];
    }
  },
  async getLimits({ commit }, { path, id }) {
    try {
      const { data } = await axiosIns.get(`${path}/${id}/currency-limits`);

      if (!data.ok) return data;

      commit("setCurrencies", []);
      return data;
    } catch (error) {
      console.log("ERROR OBTENIENDO CURRENCIES", error);
      return false;
    }
  },
  async addLimits({ commit }, { path, id, limits }) {
    try {
      const { data } = await axiosIns.post(
        `${path}/${id}/add-currency-limits`,
        {
          limits,
        }
      );

      if (!data.ok) return data;

      commit("setCurrencies", []);
      return data;
    } catch (error) {
      console.log("ERROR ADDING LIMITS", error);
    }
  },
  async addCurrencies({ commit }, { path, id, currencies }) {
    try {
      const { data } = await axiosIns.post(`/${path}/${id}/add-currency`, {
        currencies,
      });

      if (!data.ok) return commit("setSuccess", false);

      return commit("setSuccess", true);
    } catch (error) {
      console.log("ERROR ADD CURRENCIES", error);
      return commit("setSuccess", false);
    }
  },
  async addCurrenciesAdminApi({ commit }, { isCode, name }) {
    try {
      const { data, status } = await axiosInsDragonTiger.post(`/currency`, {
        isCode,
        name,
      });

      if (status > 201) return commit("setSuccess", false);
      if (!data.ok) return commit("setSuccess", false);

      commit("setSuccess", true);
    } catch (error) {
      console.log("ERROR ADD CURRENCIES", error);
      return commit("setSuccess", false);
    }
  },
  async modifyCurrencies({ commit }, { path, id, currencies }) {
    try {
      const { data } = await axiosIns.put(`/${path}/${id}/modify-currencies`, {
        currencies,
      });

      if (!data.ok) return commit("setSuccess", false);

      return commit("setSuccess", true);
    } catch (error) {
      console.log("ERROR ADD CURRENCIES", error);
      return commit("setSuccess", false);
    }
  },
  async addCurrenciesToModel(
    { commit },
    { currencies = [], uuid = "", path = "" }
  ) {
    const route = path.slice(0, path.length - 1);

    try {
      const queries = currencies.map(async (cr) => {
        return await axiosInsWheel.put(`${route}/${uuid}/add-currency`, {
          currency: cr.uuid,
        });
      });

      const data = await Promise.allSettled([...queries]);
      const errorMessages = [];
      for (let i = 0; i < data.length; i++) {
        const { status, data: dataCurrency } = data[i].value;
        if (status > 201 && dataCurrency.error) {
          const { name } = currencies[i];
          const message = `${dataCurrency.error} - ${name}`;
          errorMessages.push(message);
        }
      }

      if (!errorMessages.length) return commit("setSuccess", true);

      console.log("errorMessages", errorMessages);
    } catch (error) {
      console.log("ERROR ADD CURRENCIES TO CLIENT", error);
    }
  },
  async removeCurrenciesToModel(
    { commit },
    { path = "", uuid = "", currency = "" }
  ) {
    const route = path.slice(0, path.length - 1);
    try {
      const { data, status } = await axiosInsDragonTiger.delete(
        `/${route}/${uuid}/remove-currency/${currency}`
      );

      if (status > 201) return commit("setSuccess", false);
      if (data.error) return commit("setSuccess", false);

      commit("setSuccess", true);
    } catch (error) {
      console.log("ERROR ADD CURRENCIES TO CLIENT", error);
    }
  },
  // * FLUCTUATION
  async fetchFluctuation({ commit }, { params }) {
    try {
      const { gameType } = params;
      const fluctuation = [];

      if (gameType === GamesTypes.DragonTiger) {
        const currencyFluctuation = await store.dispatch(
          "currencies/fetchFluctuationDragon",
          { params }
        );
        fluctuation.push(...currencyFluctuation);
      } else {
        const { data } = await axiosIns.get(
          "/currencies/usd-exchange/fluctuation",
          { params }
        );
        if (data.ok) fluctuation.push(...data.historyFluctuation);
      }
      commit("setFluctuation", fluctuation);
    } catch (error) {
      console.log("ERROR FETCH FLUCTUATION", error);
    }
  },
  async fetchFluctuationDragon(_, { params }) {
    try {
      const { currencyId: currency } = params;
      let filter = {};
      let currencyExist;

      if (currency) {
        currencyExist = store.getters["currencies/getCurrencies"].find(
          (gm) => gm._id === currency
        );

        const { uuid } = currencyExist;

        filter["currency"] = uuid ? uuid : "X";
      }

      const { data, status } = await axiosInsDragonTiger(
        "/reports/currency-fluctuation",
        {
          params: {
            ...params,
            ...filter,
          },
        }
      );

      if (status > 201) return [];

      const { currency: currencyRate } = data;

      const { exchangeRateHistory, isoCode } = currencyRate[0];

      const formattedRateHistory = exchangeRateHistory.map((rate) => ({
        ...currencyExist,
        ...rate,
        createdAt: rate.date,
        short: isoCode,
        usdExchange: rate.price,
      }));

      return formattedRateHistory;
    } catch (error) {
      console.log("ERROR FETCH FLUCTUATION DRAGON", error);
    }
  },
  // CRUD
  async addCurrency({ commit }, { doc }) {
    const { short } = doc;
    const dataToSave = { ...doc, isoCode: short };
    try {
      let uuid = "1";
      try {
        const { data: rouletteApi } = await axiosInsWheel.post("currency", {
          ...dataToSave,
        });
        const { currency } = rouletteApi;
        if (currency) uuid = currency.uuid;
      } catch (error) {
        console.log("ERROR CREATING CURRENCY WHEEL", error);
        store.commit("notification/setErrors", [
          { reason: error, api: process.env.VUE_APP_WHEEL },
        ]);
      }

      const { status } = await axiosIns.post("currencies", { ...doc, uuid });

      if (status > 201) return commit("setSuccess", false);

      commit("setSuccess", true);
    } catch (error) {
      console.log("ERROR ADD CURRENCY", error);
    }
  },
  async deleteCurrency({ commit }, currency) {
    const { uuid, _id } = currency;
    try {
      const adminApi = axiosInsWheel.delete(`currency/${uuid}`);
      const rouletteApi = axiosIns.delete(`currencies/${_id}`);

      const data = await Promise.allSettled([rouletteApi, adminApi]);
      const { success, errors } = requestSuccess(...data);

      if (!success) return commit("setSuccess", false);
      commit("setSuccess", true);
      store.commit("notification/setErrors", [...errors]);
    } catch (error) {
      console.log("ERROR IN DELETE CURRENCY", error);
    }
  },
  async updateCurrency({ commit }, { uuid, _id, dataToUpdate }) {
    try {
      let data = null;

      if (!uuid) {
        let dataCurrency = null;
        let statusCurrency = null;

        try {
          const { data, status } = await axiosInsWheel.post("/currency", {
            ...dataToUpdate,
            isoCode: dataToUpdate.short,
            usdRate: dataToUpdate.usdExchange,
          });

          dataCurrency = data;
          statusCurrency = status;
        } catch (error) {
          console.log("ERORR UPDATING CURRENCY WHEEL", error);
          store.commit("notification/setErrors", [
            { reson: error, api: process.env.VUE_APP_WHEEL },
          ]);
        }

        if (statusCurrency > 201) {
          const rouletteApi = axiosIns.put(`/currencies/${_id}`, {
            ...dataToUpdate,
            uuid: 1,
          });
          data = await Promise.allSettled([rouletteApi]);
        } else {
          const uuid = dataCurrency ? dataCurrency.currency.uuid : 1;
          const rouletteApi = axiosIns.put(`/currencies/${_id}`, {
            ...dataToUpdate,
            uuid,
          });
          data = await Promise.allSettled([rouletteApi]);
        }
      } else {
        const adminApi = axiosInsWheel.put(`/currency/${uuid}`, {
          ...dataToUpdate,
          isoCode: dataToUpdate.short,
        });
        const rouletteApi = axiosIns.put(`/currencies/${_id}`, {
          ...dataToUpdate,
          uuid,
        });
        data = await Promise.allSettled([rouletteApi, adminApi]);
      }

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit("setSuccess", false);
      store.commit("notification/setErrors", [...errors]);
      commit("setSuccess", true);
    } catch (error) {
      console.log("ERROR IN UPDATE CURRENCY", error);
    }
  },
};
